import React from "react";
import { ArinimedItem } from "./types";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const PreviousNames: React.FC<{ arinimed: ArinimedItem[] }> = ({ arinimed }) => {
    const [hoveredField, setHoveredField] = useState<string | null>(null);
    const [copiedField, setCopiedField] = useState<string | null>(null);
    const handleCopy = (text: string, field: string) => {
        navigator.clipboard.writeText(text);
        setCopiedField(field);
        setTimeout(() => setCopiedField(null), 2000);
      };
    return (
        <div>
        <h3 className="mt-4 mb-2 text-xl font-bold">Previous Names (Arinimed)</h3>
        <ul>
          {arinimed.length > 0 ? (
            arinimed.map((item, index) => (
              <li key={index} className='mb-2'>
                <h4
                  className="hover:bg-btn-background font-bold mb-1"
                  onMouseEnter={() => setHoveredField(`sisu-${item?.sisu}`)}
                  onMouseLeave={() => setHoveredField(null)}
                >
                  {item?.sisu ?? 'N/A'}
                  {hoveredField === `sisu-${item?.sisu}` && copiedField !== `sisu-${item?.sisu}` && (
                    <FontAwesomeIcon
                      icon={faCopy}
                      className="cursor-pointer ml-1"
                      onClick={() => handleCopy(item?.sisu ?? 'N/A', `sisu-${item?.sisu}`)}
                    />
                  )}
                  {copiedField === `sisu-${item?.sisu}` && (
                    <span className="rounded-md border bg-btn-background p-1 ml-1 text-sm">Copied!</span>
                  )}
                </h4>
                <p><strong>Card Type:</strong> {item.kaardi_tyyp}</p>
                <p>
                  <strong>Start Date:</strong>{' '}
                  {item.algus_kpv ? new Date(item.algus_kpv * 1000).toLocaleDateString() : 'N/A'}
                </p>
                <p>
                  <strong>End Date:</strong>{' '}
                  {item.lopp_kpv && typeof item.lopp_kpv !== 'object' ? new Date(item.lopp_kpv * 1000).toLocaleDateString() : 'N/A'}
                </p>
              </li>
            ))
          ) : (
            <p>No previous names available</p>
          )}
        </ul>
      </div>
    );
};

export default PreviousNames;