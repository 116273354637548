import ContactForm from "../../../components/ContactForm/ContactForm";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout/Layout";
import PageMeta from "./PageMeta";
import { useEffect } from "react";

const TermsOfService: React.FC = () => {
    
    const title = "Terms of Service | LEIprofile by LEIpapa"
    const keywords = "Terms of Service, Terms & Conditions, Get support for LEI code, company search, LEI code search, LEI lookup, company information"
    const description = "Terms of Service. Ask LEIprofile regarding your company or LEI code. Get support and update your LEI. LEI profile by LEIpapa."
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <>
            <PageMeta title={title} keywords={keywords} description={description} />
            <Layout title={title} description={description}>
                <div className="flex flex-col justify-center items-center flex-grow mb-8">

                    <h1 className="text-4xl font-bold w-full max-w-2xl px-4 mt-8">Terms of Service</h1>
                    <div className="w-full max-w-2xl mt-4 mb-4 px-4 py-4">
                        <kbd className="mt-4">Last updated: June 15, 2024</kbd>
                        <p className="mt-4 text-justify">
                            By using the Global Corporate Index on our website leiprofile.com (hereinafter – the "Service") you accept all of the following terms of use (hereinafter – the "Terms").
                        </p>
                        <p className="mt-4 text-justify">
                            Service provider (<Link to="/lei/9845005FAED41C103C69" title="LEIpapa Company Information">LEIpapa OÜ</Link>) leaves themself the right to change these Terms without prior notice of users of the Service.
                        </p>
                        <p className="mt-4 text-justify">
                            The main objective of this Service is to promote international entrepreneurship and increase the transparency of economic activity by providing the public with a free, most accurate and impartial overview of the economic condition, solvency, reliability and stability of legal entities and other market participants.
                        </p>
                        <p className="mt-4 text-justify">
                            The Service is free of charge, except for additional services (consultations, data exports, API, data additions, LEI registrations, etc.) and special solutions (limitless access, etc.).
                        </p>
                        <h2 className="text-xl mt-4">Data provided by the Service</h2>
                        <p className="mt-4 text-justify">
                            All data provided by the Service is public, including the names and dates of birth of representatives of legal persons and other market participants.
                        </p>
                        <p className="mt-4 text-justify">
                            The Service does not store, does not process and does not publish personal data of private persons, such as real estate, economic status, biographies and media data. 
                        </p>
                        <p className="mt-4 text-justify">
                            The age of data provided by the Service does not exceed 30 days, but in some cases it may be more. When making important decisions, it is recommended to recheck data from the local sources.
                        </p>
                        <p className="mt-4 text-justify">
                            The Service provider is not responsible for the validity and accuracy of the data.
                        </p>
                        <p className="mt-4 text-justify">
                            The Service may be used only for achieving the legally allowed purposes, which can be both personal and commercial.
                        </p>
                        <p className="mt-4 text-justify">
                            The Service may use HTTP cookies from the side of API providers, which cannot be refused. The primary purpose of such cookies is to prevent the mass collection of data and to protect personal data of market participants. We can't restrict or affect the collection of such cookies (if any).
                        </p>
                        <h2 className="text-xl mt-4">Prohibited actions</h2>
                        <p className="mt-4 text-justify">
                            It is prohibited to send to the Service automated queries using software or to analyze data provided by the Service using software. The purpose of this restriction is to prevent the mass collection of listed data and the spread of spam.
                        </p>
                        <p className="mt-4 text-justify">
                            Mass collection of data provided by the Service is prohibited. More than 1000 page views in 24 hours are considered mass data collection, including for the whole organization. The purpose of this restriction is to prevent the mass collection of data and the spread of spam and to control the use of technical resources in such a way as to maintain free access to the Service for ordinary users.
                        </p>
                        <p className="mt-4 text-justify">
                            In case of violation of the Terms, the Service Provider reserves the right to restrict access to the Service to users, including to the entire organization. The Service Provider reserves the right to charge a fee for restoring access.
                        </p>
                        <h2 className="text-xl mt-4">Intellectual property rights</h2>
                        <p className="mt-4 text-justify">
                            You acknowledge and agree that the Service and its entire contents, features, and functionality, including but not limited to all information, software, code, text, displays, graphics, logo, photo, video, audio, design, presentation, selection, and arrangement, are owned by us, our licensors, or other providers of such material and are protected by the EU, US and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                        </p>
                        <h2 className="text-xl mt-4">Disclaimer of warranties</h2>
                        <p className="mt-4 text-justify">
                            THE SERVICE AND ITS CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTIES OF ANY KIND. WE DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTY OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE.
                        </p>
                        <h2 className="text-xl mt-4">Limitation of liability</h2>
                        <p className="mt-4 text-justify">
                            IN NO EVENT WILL WE, OUR AFFILIATES OR THEIR LICENSORS, SERVICE OR API PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, THE SERVICE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES.
                        </p>
                        <h2 className="text-xl mt-4">Governing law and jurisdiction</h2>
                        <p className="mt-4 text-justify">
                            These Terms and any dispute or claim arising out of or related to them, their subject matter or their formation (in each case, including non-contractual disputes or claims) shall be governed by and construed in accordance with the internal laws of the Republic of Estonia without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising out of, or related to, these Terms or the leiprofile.com website shall be instituted exclusively in the courts of the Republic of Estonia.
                        </p>
                        <hr className="mt-4" />
                        <p className="mt-4 text-justify">
                            By using the Service, you acknowledge that you have read these Terms, understood them, and agree to be bound by them. If you do not agree to these Terms, you are not authorized to use the Service. We reserve the right to change these Terms at any time, so please review them frequently.
                        </p>
                        <h2 className="text-xl mt-4">Contact information</h2>
                        <p className="mt-4 text-justify">
                            Questions or comments about the Service or these Terms may be directed to our team at <a href="mailto:info@leiprofile.com" title="Contact LEIprofile">info@leiprofile.com</a> or using the contact form below.
                        </p>
                    </div>

                    <ContactForm />
                </div>
            </Layout>
        </>
    )
}

export default TermsOfService