import React, { useEffect, useState } from 'react';
import { fetchCompanyData } from '../../../../utils/api';
import type { Tab2EE, EttevotjadItem, ArinimedItem, InfoMajandusaastaAruannetestItem, KapitalidItem } from './types';
import GeneralInfo from './GeneralInfo';
import FinancialReportTable from './FinancialReportTable';
import PreviousNames from './PreviousNames';
import CapitalTable from './CapitalTable';


const Tab2Ee: React.FC<{ companyNumber: string }> = ({ companyNumber }) => {
  const [companyData, setCompanyData] = useState<Tab2EE | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [copiedField, setCopiedField] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCompanyData(companyNumber);
        const data: Tab2EE = JSON.parse(response);
        setCompanyData(data);
      } catch (error) {
        console.error('Error fetching company data:', error);
        setError('Failed to fetch company data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [companyNumber]);

  const handleCopy = (text: string, field: string) => {
    navigator.clipboard.writeText(text);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 2000);
  };

  if (loading) {
    return <div className='text-center mt-8'>Requesting corporate registry...</div>;
  }

  if (error) {
    return <div className='text-center mt-8'>Error: {error}</div>;
  }

  if (!companyData) {
    return <div className='mt-8'>No data available</div>;
  }

  const companyInfo: EttevotjadItem | undefined = companyData.keha.ettevotjad.item[0];
  const companyName = companyInfo?.nimi || 'N/A';
  const regCode = typeof companyInfo?.ariregistri_kood === 'string' || typeof companyInfo?.ariregistri_kood === 'number'
    ? companyInfo?.ariregistri_kood
    : 'N/A';
  const companyId = typeof companyInfo?.ettevotja_id === 'string' || typeof companyInfo?.ettevotja_id === 'number'
    ? companyInfo?.ettevotja_id
    : 'N/A';
  const kmkr = typeof companyInfo?.kmkr_number === 'string' ? companyInfo?.kmkr_number : 'N/A';
  const arinimed: ArinimedItem[] = companyInfo?.yldandmed?.arinimed?.item || [];
  const infoMajandusaastaAruannetest: InfoMajandusaastaAruannetestItem[] = companyInfo?.yldandmed?.info_majandusaasta_aruannetest?.item || [];
  const kapitalid: KapitalidItem[] = companyInfo?.yldandmed?.kapitalid?.item || []; // Access Kapitalid data
  
  // Find the latest capital entry
  const latestCapital = kapitalid.reduce((latest, item) => {
    return !latest || item.algus_kpv > latest.algus_kpv ? item : latest;
  }, null as KapitalidItem | null);

  const transformedKapitalid = kapitalid.map(item => ({
    ...item,
    lopp_kpv: typeof item.lopp_kpv === 'number' ? item.lopp_kpv : 'N/A',
  }));

  const formatNumberWithSpacesAndDecimals = (number: number) => {
    return new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  return (
    <>
      <div className='w-full'>
        <h3 className='mt-8 text-2xl font-bold px-4'>{companyName}</h3>
        <div className='grid gap-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 px-4 py-4'>
          <div>
            <GeneralInfo
              companyName={companyName}
              regCode={regCode}
              kmkr={kmkr}
              companyId={companyId}
              latestCapital={latestCapital}
              handleCopy={handleCopy}
              copiedField={copiedField}
              hoveredField={hoveredField}
              setHoveredField={setHoveredField}
              formatNumberWithSpacesAndDecimals={formatNumberWithSpacesAndDecimals}
            />
          </div>
          {/* Previous Names */}
          <div>
            <PreviousNames arinimed={arinimed} />
          </div>
        </div>
        {/* Financial Reports */}
        <div className='px-4 py-4'>
          <h3 className='mb-4 text-xl font-bold'>{infoMajandusaastaAruannetest && 'Financial Reports'}</h3>
          <div>
            <FinancialReportTable reports={infoMajandusaastaAruannetest} />
          </div>
        </div>
        {/* Capital Table */}
        <div className='px-4 py-4'>
          <h3 className='mb-4 text-xl font-bold'>{kapitalid && 'Capital Information'}</h3>
          <div>
            <CapitalTable rows={transformedKapitalid} />          
          </div>
        </div>
      </div>
    </>
  );
};

export default Tab2Ee;