import ContactForm from "../../../components/ContactForm/ContactForm"
import Layout from "../../../components/Layout/Layout"
import PageMeta from "./PageMeta"
import { useEffect } from "react"

const PrivacyPolicy: React.FC = () => {
    
    const title="Privacy Policy | LEIprofile by LEIpapa"
    const keywords="Privacy Policy, Analytics, Get support for LEI code, company search, LEI code search, LEI lookup, company information"
    const description = "Privacy Policy. Ask LEIprofile regarding your company or LEI code. Get support and update your LEI. LEI profile by LEIpapa."
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return (
        <>
            <PageMeta title={title} keywords={keywords} description={description} />
            <Layout title={title} description={description}>
                <div className="flex flex-col justify-center items-center flex-grow mb-8">
                    <h1 className="text-4xl font-bold w-full max-w-2xl px-4 mt-8">Privacy Policy</h1>
                    <div className="w-full max-w-2xl mt-4 mb-4 px-4 py-4">
                        <kbd className="mt-4">Last updated: June 15, 2024</kbd>
                        <h2 className="text-xl mt-4">We do not use cookies on our website</h2>
                        <p className="mt-4 text-justify">
                            We do not use cookies on our website, and we use <a href="https://beamanalytics.io/" title="Beam Analytics" target="_blank" rel="noopener noreferrer">Beamanalytics.io</a> for web analytics, an open source tool that does not use cookies and does not generate any persistent identifiers.
                        </p>
                        <p className="mt-4 text-justify">
                            Beamanalytics.io generates a random string of letters and numbers that is used to calculate unique visitors on a website and resets this string once per day. All the data is isolated to a single day only. There is no way to know whether a person comes back to a site on another day. There is no way to get any kind of personal data from Beam Analytics, and it does not track individual people. All the data is in aggregate only.
                        </p>
                        <p className="mt-4 text-justify">
                            Beamanalytics.io minimises any data collection in general and whatever it does track is kept fully secured, encrypted and hosted on a server in the European Union to ensure it is being covered by the strict laws on data privacy.
                        </p>
                        <p className="mt-4 text-justify">
                            See Beamanalytics.io’s <a href="https://beamanalytics.io/data" title="Beam Analytics Data Policy" target="_blank" rel="noopener noreferrer">data policy</a> for the full list of data it does collect to display your web stats and which metrics it uses the data for.
                        </p>
                        <h2 className="text-xl mt-4">Account</h2>
                        <p className="mt-4 text-justify">
                            We collect and store your email address and password when you register an account on the website leiprofile.com. 
                        </p>
                        <p className="mt-4 text-justify">We collect and store your email address and password so you can log into your account at any time with your credentials.</p>
                        <p className="mt-4 text-justify">
                            Your email address, password (and other possible credentials entered during the registration) are stored using Supabase.com according to their <a href="https://supabase.com/privacy" title="Supabase.com Privacy Policy" target="_blank" rel="noopener noreferrer">privacy policy</a>. Supabase.com provides us with remote and managed database service.
                        </p>
                        <h2 className="text-xl mt-4">Contact Form</h2>
                        <p className="mt-4 text-justify">
                            We collect and store your email address when you send us a message using Contact Form. 
                        </p>
                        <p className="mt-4 text-justify">We collect and store your email address so we can send you our reply.</p>
                        <p className="mt-4 text-justify">
                            Data about you is processed using Formspree.io and their <a href="https://formspree.io/legal/privacy-policy/" title="Formspree.io Data Policy" target="_blank" rel="noopener noreferrer">data policy</a>, which provides us with analytics and the means to send a reply to you.
                        </p>
                        <p className="mt-4 text-justify">
                            When you send us a message, we ask for and keep your name (so we know how to address you), your email address (so we can contact you) and your phone number (if you decided to share it).
                        </p>
                        <h2 className="text-xl mt-4">What rights and control do you have?</h2>
                        <p className="mt-4 text-justify">
                            Because we operate in the EU, you have rights over the data about that we collect. You can:
                        </p>
                        <ul>
                            <li>- ask for a copy of data we have collected about you;</li>
                            <li>- ask us to update that data;</li>
                            <li>- ask us to delete it;</li>
                            <li>- ask us to stop using it;</li>
                            <li>- If you ask for a copy of data we have about you, we have to provide it to you free of charge. You can ask us to send it directly to a third party. We have to provide it in a commonly-used machine-readable format.</li>
                        </ul>
                        <p className="mt-4 text-justify">
                            To do this, contact <a href="mailto:info@leiprofile.com" title="Contact LEIprofile">info@leiprofile.com</a> or use the contact form below.
                        </p>
                    </div>

                    <ContactForm />
                </div>
            </Layout>
        </>
    )
}

export default PrivacyPolicy