import React from 'react';
import { Link } from "react-router-dom";

const ContactForm: React.FC = () => {
    const formspreeUrl = process.env.REACT_APP_FORMSPREE_URL;
    if (!formspreeUrl) {
        console.error('Formspree URL is not set. Please check your environment variables.');
        return <div>Error: Contact form is not available at the moment.</div>;
    }
    return (
        <div className="w-full max-w-2xl px-4">
            <form action={formspreeUrl} method="POST" className="w-full rounded-md">
                <label className="block mb-4">
                    Email<span className="text-red-500"> *</span>
                    <input type="email" name="email" className="w-full px-4 py-2 mt-2 border rounded-md" placeholder="name@company.com" required />
                </label>
                <label className="block mb-4">
                    Contact phone
                    <input type="tel" name="phone" className="w-full px-4 py-2 mt-2 border rounded-md" placeholder="+372 1234 5678" />
                </label>
                <label className="block mb-4">
                    Full Name
                    <input type="text" name="name" className="w-full px-4 py-2 mt-2 border rounded-md" placeholder="David Cooper" />
                </label>
                <label className="block mb-4">
                    Your message<span className="text-red-500"> *</span>
                    <textarea name="message" rows={5} className="w-full px-4 py-2 mt-2 border rounded-md" placeholder="Hi! I am interested in..." required />
                </label>
                <button type="submit" className="border py-2 px-4 rounded-md no-underline bg-btn-background hover:bg-btn-background-hover">Submit</button>
                <p className="mt-4 text-sm text-justify">
                    The contact form handling service is provided by our partner Formspree. By clicking the "Submit" button, you consent to the processing of personal data and agree to the <Link to="/legal/privacy" title="LEIprofile Privacy Policy">LEIprofile Privacy Policy</Link> and the <a href="https://formspree.io/legal/privacy-policy/" title="Formspree Privacy Policy" rel="noopener noreferrer nofollow" target="_blank">Formspree Privacy Policy</a>.
                </p>
            </form>
        </div>
    );
}

export default ContactForm;