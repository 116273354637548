import React from 'react';
import { Helmet } from 'react-helmet-async';

interface PageMetaProps {
  legalName: { name: string };
  countryName: string;
  lei: string;
  companyDetails: { registeredAs: string };
  flagEmoji: string;
  canonicalUrl: string;
  title: string;
  keywords: string;
  description: string;
}

const PageMeta: React.FC<PageMetaProps> = ({ legalName, countryName, lei, companyDetails, flagEmoji, canonicalUrl, title, keywords, description  }) => {
  // const title = `${legalName.name} (${companyDetails.registeredAs}), ${countryName}, LEI ${lei}`;
  // const description = `${legalName.name} (${companyDetails.registeredAs}) from ${countryName} ${flagEmoji} LEI ${lei}. Review the full and updated company information at LEIprofile.`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta property="description" content={description} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={canonicalUrl} />

      {/* OpenGraph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:image" content="/opengraph-image.png" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@leipapa_com" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="/twitter-image.png" />
    </Helmet>
  );
};

export default PageMeta;
