// src/utils/api.ts

const GLEIF_API_BASE_URL = 'https://api.gleif.org/api/v1';

// Setup environment variables for SOAP API credentials
const ARIREGISTER_USERNAME = process.env.REACT_APP_ARIREGISTER_USERNAME;
const ARIREGISTER_PASSWORD = process.env.REACT_APP_ARIREGISTER_PASSWORD;

/**
 * Fetch LEI data using either a full URL or an LEI code
 */
export const fetchLeiData = async (urlOrLeiCode: string) => {
  try {
    // Ensure that the full URL starts with 'https' and not 'http'
    const isFullUrl = urlOrLeiCode.startsWith('https');
    const url = isFullUrl ? urlOrLeiCode : `${GLEIF_API_BASE_URL}/lei-records/${urlOrLeiCode}`;
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching LEI data:', error);
    throw error;
  }
};

/**
 * Fetch data from various endpoints based on provided parameters
 */
export const fetchManagingLou = async (managingLou: string) => {
  try {
    const response = await fetch(`${GLEIF_API_BASE_URL}/lei-records/${managingLou}`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching legal entity data:', error);
    throw error;
  }
};

export const fetchRegistrationAuthority = async (registeredAt: string) => {
  try {
    const response = await fetch(`${GLEIF_API_BASE_URL}/registration-authorities/${registeredAt}`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching registration authority:', error);
    throw error;
  }
};

export const fetchValidationAuthority = async (validatedAt: string) => {
  try {
    const response = await fetch(`${GLEIF_API_BASE_URL}/registration-authorities/${validatedAt}`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching validation authority:', error);
    throw error;
  }
};

export const fetchEntityLegalForm = async (legalFormId: string) => {
  try {
    const response = await fetch(`${GLEIF_API_BASE_URL}/entity-legal-forms/${legalFormId}`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching entity legal form:', error);
    throw error;
  }
};

/**
 * Fetch auto and fuzzy completions using query strings
 */
export const fetchFuzzyCompletions = async (query: string) => {
  try {
    const response = await fetch(
      `${GLEIF_API_BASE_URL}/fuzzycompletions?field=fulltext&q=${encodeURIComponent(query)}`,
      { headers: { Accept: 'application/vnd.api+json' } }
    );
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching fuzzy completions:', error);
    throw error;
  }
};

export const fetchAutoCompletions = async (query: string) => {
  try {
    const response = await fetch(
      `${GLEIF_API_BASE_URL}/autocompletions?field=fulltext&q=${encodeURIComponent(query)}`,
      { headers: { Accept: 'application/vnd.api+json' } }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching auto completions:', error);
    throw error;
  }
};

/**
 * Fetch LEI record details based on LEI or entity name
 */
export const fetchLeiRecordByLei = async (lei: string) => {
  try {
    const response = await fetch(`${GLEIF_API_BASE_URL}/lei-records?filter[lei]=${lei}`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching LEI record by LEI:', error);
    throw error;
  }
};

export const fetchLeiRecordByName = async (name: string) => {
  try {
    const response = await fetch(`${GLEIF_API_BASE_URL}/lei-records?filter[entity.legalName]=${name}`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching LEI record by name:', error);
    throw error;
  }
};

/**
 * Fetch LEI record details from a given URL
 */
export const fetchLeiRecordDetails = async (url: string) => {
  try {
    // Ensure the URL uses HTTPS
    const secureUrl = url.startsWith('http://') ? url.replace('http://', 'https://') : url;
    
    const response = await fetch(secureUrl);
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const jsonData = await response.json();
    const { entity, registration, conformityFlag } = jsonData.data.attributes;
    
    return {
      entity: entity.legalName.name,
      country: entity.legalAddress.country,
      leiStatus: registration.status,
      entityStatus: entity.status === 'ACTIVE' ? 'Active' : entity.status,
      category: entity.category || 'UNKNOWN',
      conformityFlag: conformityFlag || 'UNKNOWN',
      companyNumber: entity.registeredAs || 'N/A',
    };
  } catch (error) {
    console.error('Error fetching LEI record details:', error);
    throw error;
  }
};

/**
 * Fetch ISINs data from a given URL
 */
export const fetchIsins = async (url: string) => {
  try {
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching ISINs:', error);
    throw error;
  }
};

/**
 * Fetch company data using SOAP request for a given company number
 */
export const fetchCompanyData = async (companyNumber: string) => {
  const soapEnvelope = `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:prod="http://arireg.x-road.eu/producer/">
      <soapenv:Body>
        <prod:detailandmed_v2>
          <prod:keha>
            <prod:ariregister_kasutajanimi>${ARIREGISTER_USERNAME}</prod:ariregister_kasutajanimi>
            <prod:ariregister_parool>${ARIREGISTER_PASSWORD}</prod:ariregister_parool>
            <prod:ariregistri_kood>${companyNumber}</prod:ariregistri_kood>
            <prod:ariregister_valjundi_formaat>json</prod:ariregister_valjundi_formaat>
            <prod:keel>eng</prod:keel>
            <prod:yandmed>1</prod:yandmed>
            <prod:iandmed>1</prod:iandmed>
            <prod:kandmed>0</prod:kandmed>
            <prod:dandmed>0</prod:dandmed>
            <prod:maarused>0</prod:maarused>
          </prod:keha>
        </prod:detailandmed_v2>
      </soapenv:Body>
    </soapenv:Envelope>
  `;

  console.log('Sending SOAP Request:', soapEnvelope);

  try {
    const response = await fetch('https://ariregxmlv6.rik.ee', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/xml',
      },
      body: soapEnvelope,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    console.log('API Response:', data); // Log the API response
    return data;
  } catch (error) {
    console.error('Error fetching company data:', error);
    throw error;
  }
};