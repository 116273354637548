import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import type { KapitalidItem } from './types';

interface GeneralInfoProps {
  companyName: string;
  regCode: string | number;
  kmkr: string;
  companyId: string | number;
  latestCapital: KapitalidItem | null;
  handleCopy: (text: string, field: string) => void;
  copiedField: string | null;
  hoveredField: string | null;
  setHoveredField: React.Dispatch<React.SetStateAction<string | null>>;
  formatNumberWithSpacesAndDecimals: (number: number) => string;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  companyName,
  regCode,
  kmkr,
  companyId,
  latestCapital,
  handleCopy,
  copiedField,
  hoveredField,
  setHoveredField,
  formatNumberWithSpacesAndDecimals
}) => {
  return (
    <div>
      <h3 className="mt-4 mb-2 text-xl font-bold">General Information</h3>
      <table>
        <tbody>
          <tr className='hover:bg-btn-background'>
            <td className="whitespace-nowrap align-top pr-4 font-bold">Registry Code</td>
            <td className="align-top pr-4">
              <p
                onMouseEnter={() => setHoveredField('regCode')}
                onMouseLeave={() => setHoveredField(null)}
              >
                {regCode}
                {hoveredField === 'regCode' && copiedField !== 'regCode' && (
                  <FontAwesomeIcon
                    icon={faCopy}
                    className='cursor-pointer ml-1'
                    onClick={() => handleCopy(String(regCode), 'regCode')}
                  />
                )}
                {copiedField === 'regCode' && <span className='rounded-md border bg-btn-background p-1 ml-1 text-sm'>Copied!</span>}
              </p>
            </td>
          </tr>
          <tr className='hover:bg-btn-background'>
            <td className="whitespace-nowrap align-top pr-4 font-bold">VAT ID (KMKR)</td>
            <td className="align-top pr-4">
              <p
                onMouseEnter={() => setHoveredField('kmkr')}
                onMouseLeave={() => setHoveredField(null)}
              >
                {kmkr}
                {hoveredField === 'kmkr' && copiedField !== 'kmkr' && (
                  <FontAwesomeIcon
                    icon={faCopy}
                    className='cursor-pointer ml-1'
                    onClick={() => handleCopy(kmkr, 'kmkr')}
                  />
                )}
                {copiedField === 'kmkr' && <span className='rounded-md border bg-btn-background p-1 ml-1 text-sm'>Copied!</span>}
              </p>
            </td>
          </tr>
          {latestCapital && (
            <tr className='hover:bg-btn-background'>
              <td className="whitespace-nowrap align-top pr-4 font-bold">Capital</td>
              <td className="align-top pr-4">
                {formatNumberWithSpacesAndDecimals(latestCapital.kapitali_suurus)} {latestCapital.kapitali_valuuta_tekstina}
              </td>
            </tr>
          )}
          <tr className='hover:bg-btn-background'>
            <td className="whitespace-nowrap align-top pr-4 font-bold">Company Internal ID</td>
            <td className="align-top pr-4">
              <p
                onMouseEnter={() => setHoveredField('companyId')}
                onMouseLeave={() => setHoveredField(null)}
              >
                {companyId}
                {hoveredField === 'companyId' && copiedField !== 'companyId' && (
                  <FontAwesomeIcon
                    icon={faCopy}
                    className='cursor-pointer ml-1'
                    onClick={() => handleCopy(String(companyId), 'companyId')}
                  />
                )}
                {copiedField === 'companyId' && <span className='rounded-md border bg-btn-background p-1 ml-1 text-sm'>Copied!</span>}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GeneralInfo;

export {}; 