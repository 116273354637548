import ContactForm from "../../components/ContactForm/ContactForm"
import Layout from "../../components/Layout/Layout";
import { useEffect } from "react";
import PageMeta from "./PageMeta";

const ContactPage: React.FC = () => {
    
    const title = "Contact Page | LEIprofile by LEIpapa";
    const description = "Contact page. Ask LEIprofile regarding your company or LEI code. Get support and update your LEI. LEI profile by LEIpapa.";
    const keywords = 'Contact page, contact us, contact LEIpapa, Contact LEIprofile, Get support for LEI code, company search, LEI code search, LEI lookup, company information'

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <PageMeta title={title} description={description} keywords={keywords}></PageMeta>
            <Layout title={title} description={description}>
                <div className="flex flex-col justify-center items-center flex-grow mb-8">
                    <h1 className="text-4xl font-bold w-full max-w-2xl px-4 mt-8">Contact</h1>
                    <div className="w-full max-w-2xl grid gap-4 md:grid-cols-2 sm:grid-cols-1 mt-4 mb-4 px-4 py-4">
                        <div className="border rounded-md px-4 py-4">
                            <h2 className="font-bold">LEIprofile Support</h2>
                            <p><a href="mailto:info@leiprofile.com" title="Send email to LEIprofile">info@leiprofile.com</a></p>
                        </div>
                        <div className="border rounded-md px-4 py-4">
                        <h2 className="font-bold">Open Hours (Estonian time)</h2>
                            <p className="text-sm">Mon, Tue, Thu, Fri 11:00 AM – 4:00 PM</p>
                            <p className="text-sm">Wed 11:00 AM – 2:00 PM</p>
                        </div>
                    </div>
                    <div className="w-full max-w-2xl px-4 mb-8">

                        <p className="font-bold">LEIprofile by LEIpapa (TM)</p>
                        <p className="mt-4">LEIpapa OÜ 🇪🇪</p>
                        <p>Email: <a href="mailto:info@leiprofile.com" title="Send email to LEIprofile">info@leiprofile.com</a></p>
                        <p>Tel.: <a href="tel:+3726138888" title="Call LEIprofile">+372 613 8888</a></p>
                        <p>Address: <a href="https://www.google.com/maps/dir/?api=1&destination=LEIpapa+Narva+mnt+13-30" title="Open LEIprofile location on Google Map">Narva mnt 13-30, Tallinn, 10151, Estonia</a></p>

                        <p className="mt-4">LEIprofile team is happy to review your case and assist you. Please, provide as much information as possible to get quality service.</p>
                    </div>

                    <ContactForm />
                </div>
            </Layout>
        </>
    )
}

export default ContactPage;