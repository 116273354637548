import React from 'react';
import { Helmet } from 'react-helmet-async'; // Import Helmet for setting meta tags
import { CompanyDetails } from '../../pages/LeiPage/CompanyDetails';
import countries from 'i18n-iso-countries';
import { Link } from 'react-router-dom';

interface LeiFaqProps {
  companyDetails: CompanyDetails;
}

const LeiFaq: React.FC<LeiFaqProps> = ({ companyDetails }) => {
  const {
    lei,
    legalName,
    legalAddress,
    headquartersAddress,
    legalForm,
    creationDate,
  } = companyDetails;

  const legalAddressCountryName = countries.getName(legalAddress?.country as string, 'en');
  const headquartersAddressCountryName = countries.getName(headquartersAddress?.country as string, 'en');

  // Variables for questions and answers
  const question1 = `What is the legal address of ${legalName.name}?`;
  const answer1 = `${legalName.name} has its legal address at ${legalAddress?.addressLines.join(', ')}${legalAddress?.city ? `, ${legalAddress.city}` : ''}${legalAddress?.region ? `, ${legalAddress.region}` : ''}${legalAddress?.country ? ` - ${legalAddressCountryName}` : ''}${legalAddress?.postalCode ? `, ${legalAddress.postalCode}` : ''}.`;

  const question2 = `Where is ${legalName.name} headquartered?`;
  const answer2 = `${legalName.name} has its headquarters at ${headquartersAddress?.addressLines.join(', ')}${headquartersAddress?.city ? `, ${headquartersAddress.city}` : ''}${headquartersAddress?.region ? `, ${headquartersAddress.region}` : ''}${headquartersAddress?.country ? ` - ${headquartersAddressCountryName}` : ''}${headquartersAddress?.postalCode ? `, ${headquartersAddress.postalCode}` : ''}.`;

  const question3 = legalForm?.id && `What is ${legalName.name}'s legal form?`;
  const answer3 = legalForm?.id && `${legalName.name} is registered as a ${legalForm.id}.`;

  const question4 = `When was ${legalName.name} registered?`;
  const answer4 = `${legalName.name} was registered on ${creationDate?.slice(0, 10).replace('T', ' ')}.`;

  const question5 = `How to register an LEI code in ${legalAddress?.country && legalAddressCountryName}?`;
  const answer5 = `To register an LEI code for the entity from ${legalAddress?.country && legalAddressCountryName}, please go to the "Register an LEI code" section of the LEIpapa app and enter the details of your entity. After the payment, you will get your LEI code shortly.`;

  const question6 = "How to transfer my LEI code to LEIpapa?";
  const answer6 = `To transfer your existing LEI code to LEIpapa, please go to the "Transfer an LEI code" section of the LEIpapa app and enter the details of your entity. Also, read the full information on LEI transfer on the LEIpapa website.`;

  // FAQ Structured Data
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": question1,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": answer1
        }
      },
      {
        "@type": "Question",
        "name": question2,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": answer2
        }
      },
      question3 && {
        "@type": "Question",
        "name": question3,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": answer3
        }
      },
      {
        "@type": "Question",
        "name": question4,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": answer4
        }
      },
      {
        "@type": "Question",
        "name": question5,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": answer5
        }
      },
      {
        "@type": "Question",
        "name": question6,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": answer6
        }
      }
    ].filter(Boolean) // Filter out undefined values
  };

  return (
    <div className="px-4">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>

      <h2 className="mt-8 mb-2 text-2xl font-bold">FAQ</h2>
      <p className="font-bold">Frequently Asked Questions about {legalName.name}</p>

      {/* FAQ Items rendered using variables */}
      <details>
        <summary>
          <h3>{question1}</h3>
        </summary>
        <div>
          <p>{answer1}</p>
        </div>
      </details>

      <details>
        <summary>
          <h3>{question2}</h3>
        </summary>
        <div>
          <p>{answer2}</p>
        </div>
      </details>

      {question3 && (
        <details>
          <summary>
            <h3>{question3}</h3>
          </summary>
          <div>
            <p>{answer3}</p>
          </div>
        </details>
      )}

      <details>
        <summary>
          <h3>{question4}</h3>
        </summary>
        <div>
          <p>{answer4}</p>
        </div>
      </details>

      <details>
        <summary>
          <h3>{question5}</h3>
        </summary>
        <div>
          <p>{answer5}</p>
        </div>
      </details>

      <details>
        <summary>
          <h3>{question6}</h3>
        </summary>
        <div>
          <p>{answer6}</p>
        </div>
      </details>

      {/* Best Practice Information */}
      <div className="px-8 py-6 text-justify mt-8 border border-l-4 bg-btn-background rounded-md">
        <strong>💡 Best Practice</strong>
        <p className="mt-4">
          Assign a unique{' '}
          <a
            href="https://leipapa.com/docs/what-is-an-lei-number/"
            target="_blank"
            rel="noreferrer"
            title="Get to know what is an LEI number"
          >
            LEI number
          </a>{' '}
          to each contact you have to identify and avoid transaction risks linked to contacts with similar names. This company{' '}
          <strong>{legalName.name}</strong> has an LEI number <strong>{lei}</strong>.
        </p>
      </div>

      {/* Contact Information */}
      <div className="text-center mt-4">
        <p>
          If {legalName.name} is your company and you would like to add any updates to the registry, please{' '}
          <Link to="/contact" title="Contact LEIprofile">contact us</Link>.
        </p>
      </div>
    </div>
  );
};

export default LeiFaq;